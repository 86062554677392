import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import logo from './logo.svg';
import './App.css';
import SelfRoster from './SelfRoster';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00a651',
      light: '#54d97f',
      dark: '#007625',
      contrastText: '#fff',
    },
    secondary: {
      main: '#7C51A1',
      light: '#44a261',
      dark: '#00450d',
      contrastText: '#fff',
    },
  },
});

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Switch>
        <Route path="/earlyvoting">
            <SelfRoster mode="prepoll"/>
        </Route>
        <Route path="/electionday">
            <SelfRoster mode="pollingday"/>
        </Route>
          <Route path="/">
        <div className="App">
          <header className="App-header">
            <h2>
                  Australian Greens volunteer self-management tool
            </h2>
                <small>
                <p>This tool only works if you click a link that was emailed to you.</p>
                  <a href="https://greens.org.au/">Take me out of here.</a>
                  </small>
              </header>
        </div>
          </Route>
          </Switch>
        </ThemeProvider>
    </Router>
  );
}

export default App;
