import React from "react";
import Typography from "@material-ui/core/Typography";
import CONFIG from './config';

export function ThankYou(data: any): React.ReactNode {
  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Thank you!
      </Typography>
      <Typography variant="subtitle1">
        {data?.contact.first_name}, we’ve received your self-rostering details.
        <br />
        One of our local organisers will confirm your shift
        via phone or email in the next few days.
        <br />
        Next: <a href="https://greens.org.au/your-vote-is-powerful" style={{ color: '#39b54a' }}>find out how to vote Greens in this election, and more about your local {CONFIG.multiplecandidates ? 'candidates' : 'candidate'}.</a>
      </Typography>
    </React.Fragment>
  );
}
