const CONFIG = {
  'nsw23': {
    contactEmail: 'campaign@nsw.greens.org.au',
    authorisation: 'Authorised by D. Hayden, The Greens NSW, 19a/1 Hordern Place Camperdown NSW 2050',
    taglines: {
      prepoll: 'Early voting runs 18-24 March',
      pollingday: 'The NSW election is on Saturday 25th March',
      boothselection: 'Select the location where you would like to volunteer',
      reviewchoices: "After you submit this form, your shift will be reviewed. Once confirmed, you'll receive an email that will include all the details of your shift."
    },
    multiplecandidates: false,
    electiondate: '2023-03-25',
    electionname: 'NSW State Election',
    logoURL: 'https://greens.org.au/sites/default/files/logo-nsw.png',
    geocentre: {
      lat: -33.8703,
      lng: 151.2089,
    }
  },
  'fed22': {
    contactEmail: {
      'ag': 'volunteer@greens.org.au',
      'act': 'campaign@act.greens.org.au',
      'nsw': 'campaign@nsw.greens.org.au',
      'nt': 'office@nt.greens.org.au',
      'qld': 'roster@qld.greens.org.au',
      'sa': 'campaign@sa.greens.org.au',
      'tas': 'volunteer@tas.greens.org.au',
      'vic': 'boothcoordinator@vic.greens.org.au',
      'wa': 'meisha.bull@wa.greens.org.au'
    },
    authorisation: 'Authorised by Matt Roberts & Willisa Osburn, The Australian Greens, 2/18 Lonsdale Street, Braddon ACT 2612',
    taglines: {
      prepoll: 'Early voting dates are yet to be confirmed',
      pollingday: 'The federal election is on Saturday 21st May',
      boothselection: 'Select the location where you would like to volunteer.',
      reviewchoices: "After you submit this form, your shift will be reviewed. Once confirmed, you'll receive an email that will include all the details of your shift."
    },
    multiplecandidates: false,
    electiondate: '2022-05-21',
    electionname: 'Federal Election',
    logoURL: 'https://greens.org.au/sites/default/files/logo-ag.png',
    geocentre: {
      lat: -30.058333,
      lng: 135.7611446,
    }
  },
  'sa22': {
    contactEmail: 'isaac@sa.greens.org.au',
    authorisation: 'Authorised by J. Wishart & K. McCusker, The Greens, 7/291 Angas Street, Adelaide SA 5000',
    taglines: {
      prepoll: 'Early voting runs from 7th-18th March',
      pollingday: 'The SA state election is on Saturday 19th March',
      boothselection: 'Select the location where you would like to volunteer.',
      reviewchoices: "After you submit this form, your shift will be reviewed. Once confirmed, you'll receive an email that will include all the details of your shift."
    },
    multiplecandidates: false,
    electiondate: '2022-03-19',
    electionname: 'SA State Election',
    logoURL: 'https://greens.org.au/sites/default/files/logo-sa.png',
    geocentre: {
      lat: -30.0583333,
      lng: 135.7611446, 
    }
  },
  'wa20': {
    contactEmail: 'ozzie.coghlan@wa.greens.org.au',
    authorisation: 'Authorised by Emma Pringle & Gareth Doogue, The Greens (WA), 91/215 Stirling St, Perth 6000',
    taglines: {
      prepoll: 'WA election early voting is happening from 24th Feb to 12th March',
      pollingday: 'The WA state election is on Saturday 13th March',
      boothselection: 'Select the location where you would like to volunteer.',
      reviewchoices: "After you submit this form, your shift will be reviewed. Once it's confirmed, you'll receive an email that will include all your details for your volunteer shift."
    },
    multiplecandidates: false,
    electiondate: '2021-03-13',
    electionname: 'WA State Election',
    logoURL: 'https://greens.org.au/sites/default/files/logo-wa.png'
  },
  'nswlg21': {
    contactEmail: 'volunteer@nsw.greens.org.au',
    authorisation: 'Authorised by Sylvia Hale, The Greens NSW, Suite D, 263-279 Broadway Glebe NSW 2037',
    taglines: {
      prepoll: '',
      pollingday: 'The NSW local government election is on Saturday 4th September',
      boothselection: "Here's where we need you the most. Choose where you prefer to help",
      reviewchoices: ''
    },
    multiplecandidates: true,
    electiondate: '2021-09-04',
    electionname: 'NSW Local Government Elections',
    logoURL: 'https://greens.org.au/sites/default/files/logo-nsw.png'
  },
  'vic22': {
    contactEmail: 'volunteer@vic.greens.org.au',
    authorisation: 'Authorised by Martin Shield for the Australian Greens Victoria, Level 1, 45 Williams St, Melbourne VIC 3000.',
    taglines: {
      prepoll: 'Early voting runs from 14th-25th November',
      pollingday: 'The VIC state election is on Saturday 26th November',
      boothselection: 'Select the location where you would like to volunteer.',
      reviewchoices: "After you submit this form, your shift will be reviewed. Once confirmed, you'll receive an email that will include all the details of your shift."
    },
    multiplecandidates: false,
    electiondate: '2022-11-26',
    electionname: 'VIC State Election',
    logoURL: 'https://greens.org.au/sites/default/files/logo-vic.png',
    geocentre: {
      lat: -36.5820518,
      lng: 144.34829
    }
  }
};

export default CONFIG['nsw23'];
